import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "gatsby-link"
import useWindowSize from "../../hooks/useWindowSize"

import "./layout.css"
import "../../fonts/styles.css"
import NotificationContext from "../../context/NotificationContext"
import ErrorBoundary from "../ErrorBoundary"
import Footer from "../Footer"
import Header from "../Header"
import Modal from "../new-ui/modal"
import PromotionalElement from "../new-ui/promotional-element"
import NotificationContainer from "../v2/notification"
import { isMobileWindow } from "../../utils/render-util"
import { useStore } from "../../context/NewStoreContext"
import { FIRST_INTERACTION_EVENTS } from "../ui/CookieConsent"

import loadable from "@loadable/component"

const NewsletterSignup = loadable(
  () => import("../new-ui/newsletter-signup/NewsletterSignup"),
  { ssr: false }
)

const Main = styled.main`
  flex: ${(props) => (props.isSubscribePage ? "0" : "1")};
  display: flex;
  min-height: ${(props) =>
    props.isHomepage ||
    props.isSizeGuide ||
    props.isLoginPage ||
    props.isSubscribePage ||
    props.is404Page
      ? `auto`
      : `90vh`};
  flex-direction: column;

  ${(props) =>
    props.isMobile || props.isProductsPage
      ? `margin-block-start: 0;`
      : `margin-block-start: -${props.theme.navbar.height};`}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) =>
    props.almostWhite
      ? props.theme.colors.grayscale[100]
      : props.theme.colors.grayscale.white};
`

const UrlActionTypes = {
  ADD_TO_CART: "add-to-cart",
}

const query = graphql`
  query {
    defaultElement: allContentfulPromotionalElement(
      filter: { title: { eq: "Global" } }
    ) {
      nodes {
        ...PromotionalElement
      }
    }
    regions: allContentfulRegion {
      nodes {
        ...Region
      }
    }
  }
`

const Layout = ({
  children,
  pullUp = false,
  fixedNav = false,
  almostWhite = false,
  navbarInWhite = false,
  navbarTransparent = false,
  hideFooter = false,
  hidePromotional = false,
  isHomepage = false,
  isSizeGuide = false,
  isLoginPage = false,
  isProductsPage = false,
  is404Page = false,
  isSubscribePage = false,
}) => {
  const { pushNotification } = useContext(NotificationContext)
  const { cart, createLineItem } = useStore()
  const [renderNewsletter, setRenderNewsletter] = useState(false)
  const data = useStaticQuery(query)
  const [promoInput, setPromoInput] = useState()
  const windowSize = useWindowSize()
  const promotionalElementRef = useRef()
  const [userInteracted, setUserInteracted] = useState(false)

  const firstInteraction = React.useCallback(() => {
    setUserInteracted(true)
  }, [])

  useEffect(() => {
    FIRST_INTERACTION_EVENTS.forEach(function (e) {
      window.addEventListener(e, firstInteraction, {
        once: true,
      })
    })

    const nlHandled = localStorage.getItem("nlp")

    if (
      window.location.pathname !== "/shopstory-editor" &&
      window.location.pathname !== "/subscribe" &&
      window.location.pathname !== "/subscribe-exclusives" &&
      !nlHandled &&
      userInteracted
    ) {
      setRenderNewsletter(true)
    }

    const handleUserInteraction = () => {
      const videoElements = document.getElementsByTagName("video")

      for (let videoElement of videoElements) {
        if (!videoElement.playing) {
          videoElement.play()
        }
      }
    }

    document.body.addEventListener("click", handleUserInteraction)
    document.body.addEventListener("touchstart", handleUserInteraction)

    return () => {
      window.removeEventListener("click", handleUserInteraction)
      window.removeEventListener("touchstart", handleUserInteraction)
      FIRST_INTERACTION_EVENTS.forEach(function (e) {
        window.removeEventListener(e, firstInteraction)
      })
    }
  }, [firstInteraction, userInteracted])

  useEffect(() => {
    const handleAddVariantFromAction = async ({
      variantId,
      quantity,
      metadata,
    }) => {
      await createLineItem
        .mutateAsync({
          variant_id: variantId,
          quantity: quantity,
          metadata: metadata,
        })
        .catch(() => {
          pushNotification({
            id: "add-from-action-failed",
            body: "Sorry, something went wrong, try again",
            dismiss: {
              duration: 3000,
            },
          })
        })

      navigate("/checkout")
    }

    if (typeof window !== "undefined" && cart?.id) {
      if (!window.location.search) return

      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      if (params.action) {
        switch (params.action) {
          case UrlActionTypes.ADD_TO_CART: {
            const variantId = params.variant_id
            const quantity = params.quantity || 1
            const metadata = params.metadata || {}

            handleAddVariantFromAction({
              variantId: variantId,
              quantity: quantity,
              metadata: metadata,
            })

            break
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.id])

  useEffect(() => {
    let result = data.defaultElement.nodes.find(
      (element) => element.node_locale === "en-US"
    )

    if (cart?.region && data.regions) {
      const region = data.regions.nodes
        .filter((region) => region.node_locale === "en-US")
        .find((region) => region.name === cart?.region?.name)

      if (region && region.promotionalElement) {
        result = region?.promotionalElement
      }
    }
    setPromoInput(result || null)
  }, [data, cart?.region])

  return (
    <ErrorBoundary>
      <Wrapper almostWhite={almostWhite}>
        <NotificationContainer />
        <Modal />

        {renderNewsletter && (
          <NewsletterSignup
            handleClose={() => {
              localStorage.setItem("nlp", true)
              setRenderNewsletter(false)
              sessionStorage.removeItem("numberOfVisitedPages")
            }}
            onComplete={() => {
              localStorage.setItem("nlp", true)
              setRenderNewsletter(false)
              sessionStorage.removeItem("numberOfVisitedPages")
            }}
          />
        )}
        {!hidePromotional && Boolean(promoInput) && (
          <PromotionalElement ref={promotionalElementRef} input={promoInput} />
        )}
        <Header
          fixedNav={fixedNav}
          navbarInWhite={navbarInWhite}
          navbarTransparent={navbarTransparent}
          withPromoElement={!hidePromotional && Boolean(promoInput)}
          isHomepage={isHomepage}
          isProductsPage={isProductsPage}
          promotionalElement={promotionalElementRef}
        />
        <Main
          pullUp={pullUp}
          isMobile={isMobileWindow(windowSize.width)}
          isProductsPage={isProductsPage}
          withPromoElement={!hidePromotional && Boolean(promoInput)}
          isHomepage={isHomepage}
          isSizeGuide={isSizeGuide}
          isLoginPage={isLoginPage}
          is404Page={is404Page}
          isSubscribePage={isSubscribePage}
        >
          {children}
        </Main>
        {!hideFooter && <Footer />}
      </Wrapper>
    </ErrorBoundary>
  )
}

export default Layout
