import * as React from "react"
import { Box, Flex } from "theme-ui"

import { useStore } from "../../../context/NewStoreContext"
import { trackExpressCheckout } from "../../../services/analytics"
import AdyenCheckout from "@adyen/adyen-web"
import "@adyen/adyen-web/dist/adyen.css"
import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types"
import { useAdyenMakePayment } from "../../../hooks/adyen/adyen"
import { navigate } from "gatsby-link"
import { GooglePayProps } from "@adyen/adyen-web/dist/types/components/GooglePay/types"
import { ApplePayElementProps } from "@adyen/adyen-web/dist/types/components/ApplePay/types"
import type { PaymentMethodsResponse } from "@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types"
import { useUpdatePaymentSession } from "medusa-react"

const ADYEN_CLIENT_KEY =
  process.env.GATSBY_ADYEN_CLIENT_KEY || "test_NAMYOJAMBJBLFKTBZOAWMYHO5ETJUQCT"
const ADYEN_ENVIRONMENT = process.env.GATSBY_ADYEN_ENVIRONMENT || "test"

const AdyenExpressCheckout = ({
  setErrorMessage,
}: {
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}) => {
  const store = useStore()
  const googlePayRef = React.useRef()
  const applePayRef = React.useRef()
  const adyenMakePaymentMutation = useAdyenMakePayment({
    cartId: store.cart?.id,
  })
  const updateSession = useUpdatePaymentSession(store.cart?.id)
  const [googlePlayOptions, setGooglePayOptions] = React.useState({
    visible: false,
    googlePay: undefined,
  })
  const [applePayOptions, setApplePayOptions] = React.useState({
    visible: false,
    applePay: undefined,
  })

  const allowedPaymentMethods: PaymentMethodsResponse | undefined =
    store.cart?.payment_session?.data

  const mappedMethods = allowedPaymentMethods?.paymentMethods?.map(
    (pm) => pm.type
  )

  React.useEffect(() => {
    const fn = async () => {
      const configuration: CoreOptions = {
        environment: ADYEN_ENVIRONMENT,
        clientKey: ADYEN_CLIENT_KEY,
        analytics: {
          enabled: true,
        },
        paymentMethodsResponse: store.cart.payment_session.data,
        onSubmit: async (state, dropin) => {
          adyenMakePaymentMutation.mutate(
            {
              paymentMethodData: state?.data?.paymentMethod,
              browserInfo: state?.data?.browserInfo,
            },
            {
              onSuccess: (res) => {
                if (res?.resultCode === "Refused") {
                  setErrorMessage(res?.refusalReasonCode)
                  dropin
                    .setStatus("error", {
                      message: res?.refusalReasonCode,
                    })
                    .remount()

                  return
                }

                updateSession.mutate(
                  {
                    provider_id: "adyen",
                    data: res,
                  },
                  {
                    onSuccess: ({ cart }) => {
                      if (!res?.action?.type) {
                        navigate(`/checkout/payment?mpr=${cart.id}`)
                      } else {
                        dropin.handleAction(res.action)
                      }
                    },
                    onError: () => {
                      setErrorMessage("10")
                      dropin
                        .setStatus("error", {
                          message: "10",
                        })
                        .remount()
                    },
                  }
                )
              },
            }
          )
        },
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component)
        },
        // Any payment method specific configuration. Find the configuration specific to each payment method: https://docs.adyen.com/payment-methods
        // For example, this is 3D Secure configuration for cards:
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: false,
            holderNameRequired: false,
            billingAddressRequired: false,
          },
        },
      }

      const checkout = await AdyenCheckout(configuration)

      const googlePayProps: GooglePayProps = {
        environment: ADYEN_ENVIRONMENT === "live" ? "PRODUCTION" : "TEST",
        countryCode: store.cart.shipping_address.country_code.toUpperCase(),
        callbackIntents: ["SHIPPING_ADDRESS"],
        shippingAddressRequired: true,
        emailRequired: true,
        shippingAddressParameters: {
          allowedCountryCodes: [
            store.cart.shipping_address.country_code.toUpperCase(),
          ],
          phoneNumberRequired: true,
        },
        isExpress: true,
        paymentDataCallbacks: {
          onPaymentDataChanged(intermediatePaymentData) {
            return {}
          },
        },
        amount: {
          value: store.cart.total,
          currency: store.cart.region.currency_code.toUpperCase(),
        },
        onAuthorized: async (paymentData) => {
          trackExpressCheckout(store.cart?.id, "GooglePay")

          await store.updateCart.mutateAsync({
            email: paymentData.email,
            shipping_address: {
              city: paymentData.shippingAddress.locality,
              phone: paymentData.shippingAddress.phoneNumber,
              postal_code: paymentData.shippingAddress.postalCode,
              first_name: paymentData.shippingAddress.name.split(" ")[0],
              last_name: paymentData.shippingAddress.name.split(" ")[1],
              address_1: paymentData.shippingAddress.address1,
              address_2: paymentData.shippingAddress.address2,
              country_code:
                paymentData.shippingAddress.countryCode.toLowerCase(),
              province: paymentData.shippingAddress.administrativeArea,
            },
            billing_address: {
              city: paymentData.shippingAddress.locality,
              phone: paymentData.shippingAddress.phoneNumber,
              postal_code: paymentData.shippingAddress.postalCode,
              first_name: paymentData.shippingAddress.name.split(" ")[0],
              last_name: paymentData.shippingAddress.name.split(" ")[1],
              address_1: paymentData.shippingAddress.address1,
              address_2: paymentData.shippingAddress.address2,
              country_code:
                paymentData.shippingAddress.countryCode.toLowerCase(),
              province: paymentData.shippingAddress.administrativeArea,
            },
          })
        },
      }

      if (mappedMethods.includes("googlepay") && googlePayRef.current) {
        try {
          const googlePay = checkout.create("googlepay", googlePayProps)

          const available = await googlePay.isAvailable()

          if (available) {
            setGooglePayOptions({
              visible: true,
              googlePay,
            })
            googlePay.mount(googlePayRef.current)
          }
        } catch (error) {
          console.error(error)
        }
      }

      const applePayProps: ApplePayElementProps = {
        shippingType: "shipping",
        countryCode: store.cart.shipping_address.country_code.toUpperCase(),
        amount: {
          value: store.cart.total,
          currency: store.cart.region.currency_code.toUpperCase(),
        },
        isExpress: true,
        buttonType: "plain",
        buttonColor: "black",
        supportedCountries: [
          store.cart.shipping_address.country_code.toUpperCase(),
        ],
        requiredShippingContactFields: ["postalAddress", "email", "phone"],
        requiredBillingContactFields: ["postalAddress", "email", "phone"],
        onError: (error) => {
          console.error(error)
        },
        onAuthorized: (resolve, reject, { payment: paymentData }) => {
          trackExpressCheckout(store.cart?.id, "ApplePay")

          store.updateCart.mutate(
            {
              email: paymentData.shippingContact.emailAddress,
              shipping_address: {
                city: paymentData.shippingContact.locality,
                phone: paymentData.shippingContact.phoneNumber,
                postal_code: paymentData.shippingContact.postalCode,
                first_name: paymentData.shippingContact.givenName,
                last_name: paymentData.shippingContact.familyName,
                address_1: paymentData.shippingContact.addressLines[0],
                address_2: paymentData.shippingContact.addressLines[1],
                province: paymentData.shippingContact.administrativeArea,
              },
              billing_address: {
                city: paymentData.billingContact.locality,
                phone: paymentData.billingContact.phoneNumber,
                postal_code: paymentData.billingContact.postalCode,
                first_name: paymentData.billingContact.givenName,
                last_name: paymentData.billingContact.familyName,
                address_1: paymentData.billingContact.addressLines[0],
                address_2: paymentData.billingContact.addressLines[1],
                country_code:
                  paymentData.billingContact.countryCode.toLowerCase(),
                province: paymentData.billingContact.administrativeArea,
              },
            },
            {
              onSuccess: (data) => {
                console.log(data)
                resolve()
              },
              onError: (error) => {
                console.error(error)
                store.createLineItem
                reject()
              },
            }
          )
        },
      }

      if (mappedMethods.includes("applepay") && applePayRef.current) {
        try {
          const applePay = checkout.create("applepay", applePayProps)

          const available = await applePay.isAvailable()

          if (available) {
            setApplePayOptions({
              visible: true,
              applePay,
            })
            applePay.mount(applePayRef.current)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }

    if (
      googlePayRef.current &&
      applePayRef.current &&
      store.cart?.payment_session?.data &&
      store.cart?.payment_session?.provider_id === "adyen" &&
      !googlePlayOptions.googlePay &&
      !applePayOptions.applePay
    ) {
      fn()
    }
  }, [
    googlePayRef,
    applePayRef,
    store.cart?.total,
    store.cart?.payment_session?.data,
  ])

  React.useEffect(() => {
    if (store.cart?.total > 0) {
      if (googlePlayOptions.googlePay) {
        googlePlayOptions.googlePay.update({
          amount: {
            value: store.cart.total,
            currency: store.cart.region.currency_code.toUpperCase(),
          },
        })
      }

      if (applePayOptions.applePay) {
        applePayOptions.applePay.update({
          countryCode: store.cart.shipping_address.country_code.toUpperCase(),
          amount: {
            value: store.cart.total,
            currency: store.cart.region.currency_code.toUpperCase(),
          },
        })
      }
    }
  }, [store.cart?.total, googlePlayOptions, applePayOptions])

  return (
    <Flex sx={{ flexDirection: ["column", "row"], gap: [2, 4] }}>
      <Box
        ref={googlePayRef}
        sx={{
          display: googlePlayOptions.visible ? "block" : "none",
          flexGrow: 1,
          flexBasis: ["unset", 0],
        }}
      />
      <Box
        ref={applePayRef}
        sx={{
          display: applePayOptions.visible ? "block" : "none",
          flexGrow: 1,
          flexBasis: ["unset", 0],
        }}
      />
    </Flex>
  )
}

export default AdyenExpressCheckout
